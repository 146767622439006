@media print {
    .navbar,
    .page-container .sidebar,
    {
        display: none !important;
    }

    .page-extended-header {
        margin-top: 1px !important;
    }

    .page {
        width: 100%;
        background-color: white;

        .main-content {
            padding-top: 15px;
        }
    }

    .page-header {
        top: 0;
        .title {
            margin-left: 0 !important;
        }
    }
}
