/*
Deze scss file bevat vast een paar wijzigingen, zodat ik bij nieuwe onderdelen wel alvast AdminTheme classes/structuur
kon gebruiken, maar het er nog steeds enigszins Kosmo uitziet
 */

.card {
    .card-header {
        background-color: transparent;
        margin-bottom: 0;
    }

    h2 {
        font-size: 18px;
    }

    &.no-padding .card-block {
        padding: 0;
    }

    .card-footer {
        .btn-link {
            color: $primary;
        }
    }
}
