.tc-card-grid {
  .card {
    .card-header {
      font-size: 12px;
      font-weight: 500;
      border: none;
      padding: 15px 15px 10px 15px;
      cursor: pointer;
      display: -webkit-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      -js-display: flex;
      display: flex;
      -webkit-box-pack: justify;
      -webkit-justify-content: space-between;
      -ms-flex-pack: justify;
      justify-content: space-between;
      -webkit-box-align: center;
      -webkit-align-items: center;
      -ms-flex-align: center;
      align-items: center;

      .ks-control {
        margin: 0;
        padding: 0;
      }
      .btn-link {
        cursor: pointer;
        height: auto;
        padding: 0;
        margin: 0;
        width: auto;
        color: #333;
      }
      .btn-link.ks-no-text>.ks-icon {
        height: auto;
        width: auto;
        padding: 0;
        margin: 0;
        font-size: 13px;
      }
    }
  }
  font-size: 12px;
  font-weight: 500;
}