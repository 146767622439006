
.tc-block-editor {
    // https://github.com/codex-team/editor.js/discussions/2286
    border: 1px solid #ced4da;
    background-color: white;

    position: relative;

    min-width: 400px;
    max-width: 600px;

    .ce-block {
        border: 1px dashed #ced4da;
    }
}

.tc-block-editor-variable {
    background-color: oklch(0.924 0.12 95.746);
    color: oklch(0.279 0.077 45.635);
    font-family: $font-family-monospace;
    padding: 2px;
}
.tc-block-editor-statement {
    background-color: oklch(0.943 0.029 294.588);
    color: oklch(0.38 0.189 293.745);
    font-family: $font-family-monospace;
    padding: 2px;
}

.nav-item a code {
    font-size: 0.875em;
    color: #d63384;
    word-wrap: break-word;
}

/* ============ desktop view ============ */
@media all and (min-width: 992px) {
    .dropdown-menu li{ position: relative; 	}
    .nav-item .submenu{
        display: none;
        position: absolute;
        left:100%; top:-7px;
    }
    .nav-item .submenu-left{
        right:100%; left:auto;
    }
    .dropdown-menu > li:hover{ background-color: #f1f1f1 }
    .dropdown-menu > li:hover > .submenu{ display: block; }
}
/* ============ desktop view .end// ============ */

/* ============ small devices ============ */
@media (max-width: 991px) {
    .dropdown-menu .dropdown-menu{
        margin-left:0.7rem; margin-right:0.7rem; margin-bottom: .5rem;
    }
}
/* ============ small devices .end// ============ */

.ce-block .qrcode-wrapper {
    text-align: center;

    i.icon {
        font-size: 8rem;
        font-style: normal;
    }
}
