/* Copyright (C) Internetbureau Forwart - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */

.ui-sortable-helper {
  .btn { display: none; }
}

.fw-list-collection {
  list-style-type: none;
  margin: 0;
  padding: 0;
  li {
    margin: 0;
  }
  input {
    width: 350px;
    display: inline-block;
    margin-right: 10px;
    margin-bottom: 5px;
  }
  .a {
    display: inline-block;
    margin: 5px 10px;
  }
}