.navbar-dark .navbar-nav .nav-link.btn-secondary {
    color: $primary;
}

@each $env-name, $env-color in $env-colors {
    body.env-#{$env-name} {
        .navbar-dark .navbar-nav .nav-link.btn-secondary {
            color: $env-color;
        }
    }
}
