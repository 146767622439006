$staging-header-color: #ff7c00;
body.staging {
  .ks-navbar {
    background: $staging-header-color;

    .nav-item {
      border-color: $staging-header-color;

      &:hover, &.ks-active, &.show {
        background: darken($staging-header-color, 10%);
      }
    }

  }

  .ks-navbar > .ks-wrapper > .nav > .ks-navbar-actions > .ks-user {
    border-right-color: $staging-header-color;
  }
}

$development-header-color: #ef5350;
body.development {
  .ks-navbar {
    background: $development-header-color;

    .nav-item {
      border-color: $development-header-color;

      &:hover, &.ks-active, &.show {
        background: darken($development-header-color, 10%);
      }
    }

  }

  .ks-navbar > .ks-wrapper > .nav > .ks-navbar-actions > .ks-user {
    border-right-color: $development-header-color;
  }
}