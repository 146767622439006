@import "../frontend/_seatplan.scss";

.sp-seatplan {
  border: 1px solid #ddd;
}

.sp-seat {
  border: 1px solid #ddd;
  text-align: center;

  &.cart { background-color: lighten($warning, 25%); }
  &.order { background-color: lighten($danger, 25%); }
  &.hidden { border: 1px dashed #ccc; color: #ccc; display: block; }
  &.unavailable { opacity: 1; }
  &.available { background-color: white; }

    &.highlight {
        border-color: black;
        background-color: $warning;
    }

  &.end-of-row {
    border-color: #666;
  }

  a {
    color: black;
  }
}

.sp-seatplan.mode-backend-preview {
  .sp-seat {
    &.category-blue       { background-color: lighten($category-blue, 25%); }
    &.category-green      { background-color: lighten($category-green, 25%); }
    &.category-orange     { background-color: lighten($category-orange, 25%); }
    &.category-red        { background-color: lighten($category-red, 25%); }
    &.category-lightblue  { background-color: lighten($category-lightblue, 25%); }
    &.category-purple     { background-color: lighten($category-purple, 25%); }
  }
}

.sp-seatplan.mode-backend-order.with-categories {
  .sp-seat {
    &.order { background-color: #ddd !important }
    &.cart { background-color: #eee !important }
    &.category-blue       { background-color: rgba($category-blue, .5); }
    &.category-green      { background-color: rgba($category-green, .5); }
    &.category-orange     { background-color: rgba($category-orange, .5); }
    &.category-red        { background-color: rgba($category-red, .5); }
    &.category-lightblue  { background-color: rgba($category-lightblue, .5); }
    &.category-purple     { background-color: rgba($category-purple, .5); }
  }
}

.sp-row {
  &.hidden {
    display: block;
    .sp-row-label { color: #ccc; }
  }
}

.seating-container {
  position: relative;
}
#tc-seatplan-editor-app {
  .sp-seat {
    &.available { cursor: default; } // Don't show hand cursor on available seats, because they're not clickable
  }
}

#context-menu {
  position: absolute;
  .dropdown-menu {
    font-size: 13px;
  }
    &.show {
        .dropdown-menu {
            display: block;
        }
    }
}
