$tc-price-width: 50px;

.tc-cart {
  .cart-item,
  .cart-payment-methods,
  .cart-total {
    display: flex;
    align-items: center;
    justify-content: left;
    margin-bottom: 15px;
    flex-wrap: wrap;
  }

  .cart-item {
    align-items: flex-start;
  }

  .cart-item-quantity {
    flex: none;
    width: $tc-price-width;
  }

  .cart-item-name,
  .paymentMethod,
  .cart-total-description {
    flex: 1 1 auto;
  }

  .cart-item-price,
  .cart-total-value,
  .cart-payment-total,
  .tc-transaction-fee{
    width: 120px;
    flex: none;
    text-align: right;
  }

  .cart-item-remove {
    padding-left: 10px;
  }

  .cart-original-price {
    text-decoration: line-through;
    color: #999;
  }

  .cart-grand-total {
    font-weight: bold;
    font-size: 1.5em;
  }

  .cart-seats {
    width: 100%;
    font-size: .9em;
    ul {
      color: #777;
      margin: 0 0 5px $tc-price-width;;
      padding: 0;
      list-style-type: none;

      li {

      }
    }
    a {
      display: block;
      margin-left: 50px;
      font-style: italic;
    }
  }

  .cart-items {
    > div {
      padding: 6px;
      background-color: #fff;
      margin-bottom: 0;
      border-bottom: 1px solid #e2e2e2;
    }
  }
}

#tc-order-form {
  fieldset.payment {
    padding: 10px;
    margin-top: 25px;

    .cart-payment-methods {
      margin-bottom: 0;
    }
  }

  fieldset.totals {
    padding: 15px;
    margin-top: 25px;
    background-color: #f5f5f5;

    .cart-payment-methods {
      margin-bottom: 0;
    }

    .cart-total {
      padding: 6px;
      margin-bottom: 0;
      border-bottom: 1px solid #e2e2e2;

      &.cart-grand-total {
        border-bottom: 0;
        margin-bottom: 10px;
      }
    }
  }
}

.tc-terms.input-group-addon {
  white-space: normal;

    .input-group {
        display: inline; // snell hack
    }
}

#giftcardHelp {
    margin-left: 20px;
    font-size: 0.8rem;
    max-width: 350px; // anders past het label er rechts niet meer naast
}
