.fw-datagrid-container {
  .fw-datagrid-header {
    border: solid 1px #dee0e1;
    border-bottom: none;
    .col {
      padding: 5px;
    }
  }

  .fw-datagrid-body {
    margin-top: 0;
    border: solid 1px #dee0e1;
    .col {
      padding: 0 !important;
    }
  }

  .fw-datagrid-footer {
    margin-top: 10px;
  }

  .table {
    th {
      border-top: none;
    }
    margin: 0;
  }

  .datagrid-search-form {
    float: right;
  }
}

.fw-datagrid-headeractions {
  margin-bottom: 10px;
}

tr.tc-muted-row {
  opacity: 0.7;
}

.table td ul {
  padding-left: 10px;
}