//.table[fwat-sticky-table-header].is-sticky {
//    thead {
//        position: sticky;
//        tr {
//            th {
//                background-color: white;
//                position: sticky;
//                top: 60px;
//                z-index: 10;
//            }
//        }
//    }
//}

.fwat-sticky-table-backdrop {
    display: none;
    position: absolute;

    background-color: rgb(255,255,255,0.75); // TODO: IS dit een CSS var?
    backdrop-filter: blur(8px);

    border-bottom: 1px solid rgba(0, 0, 0, 0.125); // TODO: Is dit een css var?
    z-index: 9;
    top: 120px; // TODO: IS dit een css var?
    width: 100%;

    // Hiehgt will be automatically updated
    height: 60px;

    .container {
        // TODO: Dit werkt nog niet goed, wil eigenlijk
        padding-left: 30px;
        padding-right: 30px;

        overflow: hidden;
    }

    &.is-sticky {
        display: block;
    }
}

//body.sidebar-position-fixed {
//    .fwat-sticky-table-backdrop {
//        .container {
//            padding-left: 15px;
//            padding-right: 15px;
//            margin-right: 0;
//            margin-left: 16.6666%;
//        }
//    }
//}
//
