@import "_variables.scss";

@import "~admin-theme-bundle/assets/scss/adminTheme.scss";

@import "_vendors.scss";
@import "_datagrid.scss";
@import "_forms.scss";

//@import "_header.scss";
@import "_pdf-viewer.scss";

// Special classes on staging
@import "_staging.scss";

@import "_login.scss";
@import "_header.scss";
@import "_dashboards.scss";
@import "_card-grid.scss";
@import "_scanners.scss";
@import "_formbuilder.scss";
@import "_seatplan.scss";
@import "_cart.scss";
@import "_salesReport.scss";
@import "_print.scss";
@import "_badges.scss";
@import "_crewPlanning.scss";
@import "_blockEditor.scss";
@import "_webshopItemCollection.scss";

@import "_kosmo_conversion.scss";
@import "_accreditation.scss";

@import "_fwat_image"; // TODO: Move to Admin theme
@import "_sticky_table_header";

// placeholder items (in sidebar)
.nav-item.nav-placeholder {
  opacity: 0.2;
  :hover {
    a {
      background-color: transparent !important;
    }
  }
}

// table in card (generic styling)
.card .card-block .table {
  margin-bottom: 0;
  tr:first-child td {
    border-top: none;
  }
}

// Organisation menu in top (didn't change class, because there are too many dependencies)
.ks-messages {
  .ks-message {
    height: 65px !important;
  }
  .dropdown-menu {
    height: 298px !important;
  }
}

.btn.btn-sm {
  > .ks-icon {
    height: 27px;
    width: 27px;
  }
}

.ks-tabs-full-page .nav-item .ks-icon {
  font-size: 20px;
}

// Make sure forms aren't too wide
.tc-limit-width {
  max-width: 700px !important;
}

// Special warning card-block, to be appended at the footer of a card
.card-block.tc-warning {
  background-color: lighten($warning, 25%);
  border-top: solid 1px #dee0e1;

  .card.panel-table & {
    padding: 10px;
  }
}

// Align version in sidebar
.tc-sidebar-version {
  padding: 10px 27px;
  position: fixed;
  bottom: 0;
  color: #636c72;
  z-index: 999;

  .ks-sidebar-compact & {
    display: none;
  }

  .ks-sidebar-compact.ks-sidebar-compact-open & {
    display: block;
  }
}

// Fix alignment of the menu toggle (hamburger menu), so it has the same spacing left and right
.ks-navbar .navbar-brand .ks-sidebar-toggle {
  margin-right: 25px;
}

// TODO: [Luuk] - Ik heb niet echt het gevoel dat dit heel moi is, maar het werkte in ieder geval voor de demo
.card + .card {
  margin-top: 0px;
}
.card {
  margin-bottom: 30px;
}

// Badges in headers
h2 .badge {
  font-size: 10px;
  vertical-align: middle;
}

// Item list
.tc-item-list {
  .tc-item {
    input {
      // is maar even quick and dirty, zodat het er in ieder geval enigszins uitziet.
      float: left;
      width: 70px;
      margin-right: 10px;
      position: relative;
      top: 4px;
    }
  }
  margin-bottom: 15px;
}

table.tc-item-list {
  td.item-quantity {
    width: 143px;
    padding: 0 20px;
    vertical-align: middle;
    input {
      top: 0;
    }
  }

  td {
    label {
      margin-bottom: 0;
    }
  }
}

.hidden {
  display: none;
}

.tc-timeline {
  pre {
    max-width: 800px;
    overflow-x: scroll;
  }
}

.tc-page-header {
  padding: 30px;
  background-color: #f5f6fa;
  margin: -30px -30px 30px -30px;
  h3 {
    margin-bottom: 5px;
  }
  p {
    color: #858585;
    margin-top: 5px;
    margin-bottom: 0;
  }
}

td .td-ticket-status-badge {
  // looks better in overviews when they're all the same size
  min-width: 80px;
}

// Geen pointer op de nav-wizard als het geen link is. Gaat namelijk mis bij onboarding wizard
.nav-wizard .nav-item {
    cursor: default !important;
    &.nav-link {
        color: #868e96;
    }
}

@media (max-width: 576px) {
  // Override padding on mobile
  body > .ks-container .ks-page > .ks-content > .ks-body > .container-fluid,
  body > .ks-container .ks-page > .ks-content > .ks-body > .ks-nav-body-wrapper > .container-fluid,
  body .ks-page-container .ks-page > .ks-content > .ks-body > .container-fluid,
  body .ks-page-container .ks-page > .ks-content > .ks-body > .ks-nav-body-wrapper > .container-fluid {
    padding: 10px !important;
  }

  .ks-rows-section > .row + .row {
    margin-top: 10px;
  }

  .card {
    margin-bottom: 10px;
  }
}

.ks-card-widget .card-block.tc-chart-container {
  padding: 0 !important;
}

.card.panel-table .ks-controls {
  font-size: 20px;
}

// Used on app/Resources/views/backend/crew/position/shifts.html.twig
td.tc-compact-cell {
  padding: 5px 10px !important;

  .progress {
    margin-top: 2px;
  }
}

$roleTableColumnWidth: 125px;
$roleTableHeaderHeight: 68px;

table.tc-role-table {
    position: relative;
    border-collapse: collapse;

    thead {
        tr {
            position: sticky;
            top: 0;
            z-index: 9; // onder subheader
            min-height: $roleTableHeaderHeight;
            height: $roleTableHeaderHeight;
        }

        .role-header {
            max-width: $roleTableColumnWidth;
            text-align: center;
        }

        .role-description {
            width: $roleTableColumnWidth;
            max-width: $roleTableColumnWidth;
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
        }

        .role-name {
            width: $roleTableColumnWidth;
            max-width: $roleTableColumnWidth;
            font-size: 0.6rem;
            color: $text-muted;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
        }
    }

    //tbody {
    //    width: 100%;
    //    max-height: 500px;
    //    overflow-y: auto;
    //    display: block;
    //}

    .tc-sticky-role-column {
        position: sticky;
        left: 0;
    }
}

.js-table-dropdown {
  .table-dropdown-toggle {
    cursor: pointer;
    &:hover {
      color: $primary;
    }
    .icon-closed, .icon-open {
      display: none;
    }
    &.dropdown-open {
      .icon-open {
        display: block;
      }
    }
     &.dropdown-closed {
      .icon-closed {
        display: block;
      }
    }
  }
}

.tc-shifts-table {
  select {
    margin-bottom: 5px;
  }
  .js-datetime {
    width: 200px;
    display: inline-block;
  }
  .time-label {
    display: inline-block;
    width: 26px;
  }
}

[v-cloak] {
  display: none !important;
}

.tc-statistic-card {
    padding: 10px;
    text-align: center;

    .tc-statistic-amount {
        font-size: 2rem;
        font-weight: bold;
    }
}

.tc-order-info-table {
    ul.seat-list {
      padding-left: 30px;
      font-size: 11px;
    }
}


// Extra tussenkopje uit kosmo
.sidebar .sidebar-wrapper .ks-header {
    font-size: 9px;
    font-weight: 500;
    padding: 10px 27px 5px;
    color: #858585;
    text-transform: uppercase;
    margin-top: 10px;
}

.sidebar {
    .sidebar-wrapper {
        background-color: $sidebar-bg-color !important;
    }

    .sidebar-search input {
        background-color: $sidebar-bg-color !important;
        color: $sidebar-navitem-color;
        border: 1px solid $sidebar-border-color;

        &::-webkit-input-placeholder {
            color: #5a6872;
        }
    }

    .tc-sidebar-app-button {
        width: 80%;

        color: #fff;
        background-color: #171a1c;
        border-color: #171a1c;
    }
}

// Ook toevoegen in admin theme?
// Outline badges
@each $color, $value in $theme-colors {
    .badge-outline-#{$color} {
        border: $border-width solid $value;
        color: $value !important;
        background: transparent !important;
    }
}

.page.page-dark {
    background-color: #1f2326;
}

.progress-event-capacity {
    min-width: 100px;
}

.text-black {
    color: black !important;
}

.card .card-header.card-subheader {
    background-color: rgba(0,0,0,.03);
    border-top: 1px solid rgba(0,0,0,.125);
}

button.tc-toggle-button {
    border: none;
    background-color: transparent;

    .icon {
        margin-right: 5px;
    }

    &[aria-expanded="false"] .tc-icon-collapsed {
        display: inline-block;
    }
    &[aria-expanded="false"] .tc-icon-expanded {
        display: none;
    }
    &[aria-expanded="true"] .tc-icon-collapsed {
        display: none;
    }
    &[aria-expanded="true"] .tc-icon-expanded {
        display: inline-block;
    }
    > span {
        display: none;
    }
}

//button[data-tc-collapse-icon] {
//    > span {
//        display: none;
//    }
//}
//button[data-tc-collapse-icon][aria-expanded="false"] {
//    &:before {
//        @include fa-icon;
//        content: fa-content($fa-var-angle-down);
//    }
//}
//button[data-tc-collapse-icon][aria-expanded="true"] {
//    &:before {
//        @include fa-icon;
//        content: fa-content($fa-var-angle-up);
//    }
//}

.br-1 {
    border-right: 1px solid #e2e2e2;
}

.bl-1 {
    border-left: 1px solid #e2e2e2;
}

.br-2 {
    border-right: 2px solid #e2e2e2;
}

.bl-2 {
    border-left: 2px solid #e2e2e2;
}


table.table.table-compact {
    thead, .table-head, tbody, .table-body, tfoot, .table-footer {
        td, th, .table-cell {
            padding: 0.3rem 0.2rem;
        }
    }
}

[data-toggle=table-select-all],
[data-toggle=table-select-row] {
    width: 16px;
    height: 16px;
}

@media (max-width: 576px) {
    .search-form.quick-search-form {
        display: block;
        //order: 9;

        .input-group {
            height: 30px;
        }

        input {
            padding: 0 0.6rem;
        }

        button {
            margin-right: 0;
            padding-right: 0;
            height: 30px;
            width: 30px;
            padding: 0;
            justify-content: center;
        }
    }
}

.opacity-50 {
    opacity: 0.5;
}

@media (max-width: 576px) {
    .responsive-table-buttons {
        margin-left: 10px !important;
        // TODO: Overleg met Luuk of we dit ook in de admin theme willen
        // Nu moet je ook `class: 'icon',` toevoegen aan de fwat_button call
        .btn.fw-btn-icon {
            padding: 0;
            width: 30px;
            height: 30px;
            justify-content: center;
            align-items: center;

            .text {
                display: none;
            }

            .icon {
                margin-right: 0;
                padding-right: 0;

                &:after {
                    display: none;
                }
            }
        }
    }
}

.btn-block {
    width: 100%;
}

[data-bs-toggle=collapse] {
    .btn {
        min-width: 40px;
        padding-left: 5px;
        padding-right: 5px;
    }

    i.is-collapsed { display: none }
    i.not-collapsed { display: block }

    &.collapsed {
        i.is-collapsed { display: block }
        i.not-collapsed { display: none }
    }
}

// Fix styling van navbar met search en andere dingen op kleinere breakpoints.
// TODO: als navbar styling zo beter gaat dan ook mergen naar admin-theme
.navbar.fw-navbar {
    flex-wrap: nowrap;
    [data-fw-at-search-bar] {
        max-width: 250px;
        margin-right: auto;
    }

}

.card .card-header .card-title small {
    font-size: 0.7rem;
}

tr.row-striped {
    background-color: rgba(0, 0, 0, 0.05);
}

// order card header
@include media-breakpoint-up(sm) {
    .w-sm-100 {
        width: 100% !important;
    }
}

.page-extended-header {
    flex-wrap: wrap;

    .tc-header-content {
        flex: 1 1 auto;
    }

    @include media-breakpoint-down(md) {
        .tc-header-content {
            width: 100%;
        }

        .tc-header-actions {
            width: 100%;
            .btn {
                width: 100%;
            }
        }
    }

}

.statistic-card.success a {
    color: $success;
}

.statistic-card.warning a {
    color: $warning;
}

.statistic-card.danger a {
    color: $danger;
}

.statistic-card.info a {
    color: $info;
}


.page-sub-header.compact {
    .content-wrapper {
        height: 50px;
    }

    .nav.tabs.tab-header {
        .nav-link {
            padding: 0.1rem 0.5rem;
        }
    }
}

.dropdown .dropdown-menu.dropdown-menu-end {
    right: 0;
    left: auto;
}

.modal-mask {
    position: fixed;
    z-index: 9998;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, .5);
    display: table;
    transition: opacity .3s ease;
}

.modal-wrapper {
    display: table-cell;
    vertical-align: middle;
}

@include media-breakpoint-up(md) {
    .fs-md-3 {
        font-size: $h3-font-size;
    }
    .fs-md-4 {
        font-size: $h4-font-size;
    }
    .fs-md-5 {
        font-size: $h5-font-size;
    }
}

.tc-mail-preview {
    width: 100%;
    min-height: 500px;
    border: 2px inset
}

.card-block ul.pagination {
    // Nodig zodat dropdowns niet onder de paginering vallen (DEV-50)
    position: relative;
    z-index: 0;
}

.table-xs {
    th, td {
        padding: 0.2rem !important;
    }
}

.tc-filter-bar {
    .form-control-sm {
        height: calc(20px - 0.5rem);
        padding: 0 0.25rem;
        font-size: 0.7rem;
    }
}
