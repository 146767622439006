.page-sub-header {
    z-index: 10;
}

.page-header-nav {
    .nav-item.active {
        font-weight: bold;
    }
}

body.no-sidebar .page.has-sub-header .main-content {
    padding-top: 135px;
}

.card .card-title .badge .icon {
    color: white;
}

// https://blogs.perficient.com/2021/01/18/freezing-row-and-column-in-htms-table-using-css/
$group-label-width: 100px;
$grouped-row-label-width: 200px;
$row-label-width: 250px;
.form-group.row.form-matrix-collection-field {
    margin-bottom: -25px;

    .col-12 {
        padding-left: 0;
        padding-right: 0;
    }

    .table-responsive {
        max-height: calc(90vh - 120px);
        overflow-y: scroll;
    }

    table {

        thead {
            th {
                position: sticky;
                top: 0;
                z-index: 9; // onder subheader

                border-top: 1px solid $primary;
                border-bottom: none;
                background-color: $primary;
                color: $white;
            }
            tr {
                z-index: 9;
                //box-shadow: -2px 5px 5px rgba(0,0,0,0.2);
            }
        }

        tbody{
            th {
                z-index: 8; // onder table header
            }
        }

        th, td {
            vertical-align: middle;
            padding: 0.5rem 0.75rem;
        }

        .row-group-label, .row-label {
            background-color: $gray-200;
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
            position: sticky;
        }

        tr:first-child {
            .row-group-label, .row-label {
                //box-shadow: inset 0 5px 5px rgba(0,0,0,0.2);
                border-top: none;
            }
        }

        tr:nth-child(odd) {
            .row-group-label, .row-label {
                background-color: $gray-300;
            }
        }

        .row-label {
            width: $row-label-width;
            min-width: $row-label-width;
            max-width: $row-label-width;
            left: 0;

            //box-shadow: 5px 0 5px rgba(0,0,0,0.2);
        }

        &.has-vertical-group {
            .table-name {
                width: ($group-label-width + $grouped-row-label-width);
                min-width: ($group-label-width + $grouped-row-label-width);
                max-width: ($group-label-width + $grouped-row-label-width);
                left: 0;
            }

            .row-group-label {
                width: $group-label-width;
                min-width: $group-label-width;
                max-width: $group-label-width;
                left: 0;
            }

            .row-label {
                width: $grouped-row-label-width;
                min-width: $grouped-row-label-width;
                max-width: $grouped-row-label-width;
                left: $group-label-width;
            }
        }
    }
}

textarea.acc-short-name.form-control {
    min-height: 60px;
    height: 60px;
    resize: none;
    text-align: center;
}

.accreditation-app-container {
    .form-control[type=number] {
        min-width: 60px;
        padding-right: 0; // omdat die stepper zoveel ruimte inneemt, maar je hem standaard niet ziet
    }
}
