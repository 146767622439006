@import 'vue-multi-select/dist/lib/vue-multi-select.css';

.tc-crew-planning-app {

	margin-top: 0;
    background-color: white;

    display: flex;
    flex-direction: row;

	.tc-planning-sidebar {
		&.collapsed {
			display: none;
		}
		flex: 0 0 300px;
	}

	.tc-planning-sections {
		flex-direction: column;
		align-items: stretch;
		height: 100%;
        flex-grow: 1;
	}

	.tc-planning-section {
		flex: 0 0 500px;
		display: flex;
		flex-direction: column;

        .filter-bar {
            border: 1px solid #dee0e1;
            border-bottom: none;
            padding: 8px;
        }
		.filters {
			.filter {
				display: inline-block;

				&.is-active {
					background-color: #fffcb0;
				}
			}

            .btn-select {
                font-size: 9px;
                padding: 2px;
                min-width: 120px;
                margin-left: 2px;
                height: 25px;

            }
            .is-active .btn-select {
                background-color: #fffcb0;
            }

			.form-control {
				font-size: 9px;
				padding: 2px;
				width: 120px;
				margin-left: 2px;
				height: 25px;
			}

			.btn {
				font-size: 9px;
				height: 25px;
				padding: 2px 10px;
			}

		}

		.tc-planning-table {
			flex-grow: 1;
			height: 500px;
			overflow-y: scroll;
		}
	}

	.tc-person-selection {
		border-top: 1px solid #dee0e1;
		padding: 2px 10px;
	}

	table {
		tr.is-selected {
			background-color: #fffcb0;
		}
	}

	.tc-planning-sidebar.expanded {
		border-left: 1px solid #dee0e1;
		padding: 10px;

		ul {
			padding-left: 20px;
		}
	}
}
