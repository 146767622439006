.tc-sales-report {
  .card-header {
    font-size: 1.2rem;
    font-weight: bold;
  }

  .card-block {
    padding: 0;
  }

  .tc-period {
    table {
      width: 220px;
      margin: 0 auto;
      font-size: 0.9rem;
    }
  }

  .tc-period-select {
    select { margin-right: 5px }
  }

  tr:first-child th {
    border-top: none;
  }

  .tc-item-sub-row td {
    font-size: 0.7rem;
    padding: 5px 20px 5px 30px;
    color: #999;
  }

  .tc-subtotal {
    border-top: 1px solid #333 !important;
    font-weight: bold;
  }

  .tc-no-results td {
    color: #bbb;
  }

  tfoot td {
    padding: 5px 20px;
  }

  .results-table {
    margin: 10px auto;
    width: 500px;
  }

  .tc-extra-toppadding th,
  .tc-extra-toppadding td{
    padding-top: 20px;
  }

  .tc-grand-total td {
    padding-top: 20px;
    font-size: 1rem;
    font-weight: bold;
  }
}