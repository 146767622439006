.badge-outline-primary {
    color: $primary;
    background-color: transparent;
    background-image: none;
    border-color: $primary;
    border: 1px solid;
}

.badge-outline-secondary {
    color: $secondary;
    background-color: transparent;
    background-image: none;
    border-color: $secondary;
    border: 1px solid;
}

.badge-outline-success {
    color: $success;
    background-color: transparent;
    background-image: none;
    border-color: $success;
    border: 1px solid;
}

.badge-outline-danger {
    color: $danger;
    background-color: transparent;
    background-image: none;
    border-color: $danger;
}

.badge-outline-warning {
    color: $warning;
    background-color: transparent;
    background-image: none;
    border-color: $warning;
    border: 1px solid;
}

.badge-outline-info {
    color: $info;
    background-color: transparent;
    background-image: none;
    border-color: $info;
    border: 1px solid;
}

.badge-outline-light {
    color: $light;
    background-color: transparent;
    background-image: none;
    border-color: $light;
    border: 1px solid;
}

.badge-outline-dark {
    color: $dark;
    background-color: transparent;
    background-image: none;
    border-color: $dark;
    border: 1px solid;
}

.badge-responsive {
    min-height: 16px;
}

.split-badge {
    padding: 0;
    .badge-text {
        padding: 0.35em 0.65em;
    }
    .badge-addon {
        border-left: 1px solid white;
        padding: 0.35em 0.65em;
    }
    .btn {
        font-size: 0.6rem;
        font-weight: 700;
        line-height: 1;
    }
}
