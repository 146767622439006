$fw-at-project-modules: (
    "croppable-image": true,
    "statistic-card": true,
);

$ticketcrew-blue: #00a1bd;

// This color can be overriden from the client style in the frontend (Client.themeColor)
$primary: $ticketcrew-blue !default;
$info: #00ccf0;

$sidebar-logo-bg: url('/assets/img/logo-white.svg');
$sidebar-logo-min-height: 57px;
$sidebar-logo-width: 150px;

$gray-100: 								#f8f9fa !default;
$gray-200: 								#e9ecef !default;

$sidebar-compact-width:					60px !default;
$sidebar-bg-color:						#2b3034 !default;
$sidebar-border-color:					lighten($sidebar-bg-color, 5%) !default;
$sidebar-header-color:					#8896a1 !default;
$sidebar-footer-color:					#8896a1 !default;
$sidebar-navitem-color:					#8896a1 !default;
$sidebar-navitem-icon-color: 			#8896a1 !default;
$sidebar-navitem-hover-border-color:	#8896a1 !default;
$sidebar-navitem-active-bg-color:		#252a2e !default;
$sidebar-navitem-active-color:			white !default;
$sidebar-navitem-icon-active-color:		$primary !default;
$sidebar-navitem-hover-bg-color:		#252a2e !default;
$sidebar-navitem-hover-color:			#fff !default;
$sidebar-dropdown-bg-color:				darken($sidebar-bg-color, 5%) !default;
$sidebar-dropdown-border-bottom-color:	lighten($sidebar-border-color, 5%) !default;
$sidebar-dropdown-active-bg-color:		$primary !default;
$sidebar-dropdown-active-color:			#fff !default;
$sidebar-dropdown-icon-active-color:	#fff !default;
$sidebar-dropdown-item-color:			$sidebar-navitem-color !default;
$sidebar-dropdown-item-hover-color:		$sidebar-navitem-hover-color !default;
$sidebar-dropdown-item-active-color:	$primary !default;
$sidebar-dropdown-item-icon-color:		$sidebar-navitem-icon-color !default;
$sidebar-dropdown-item-icon-active-color:$primary !default;
$sidebar-dropdown-item-icon-hover-color:white !default;

$page-header-border-color: #dee0e1;
//$page-bg-color: #fff;

$custom-control-disabled-indicator-bg:  #e9ecef;
$custom-control-disabled-description-color:  #ced4da;


$login-card-bg-color:					rgba(255, 255, 255, .75) !default;
$login-card-border-color:				rgba(255, 255, 255, .95) !default;

$env-colors: (
    dev: #dc3545,
    staging: #ff7c00
);
