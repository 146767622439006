$ks-default-text-color-light: #858585;
.tc-chart-orders {
  .c3-line {
    stroke-width: 2px;
  }

  .c3-axis-y, .c3-axis-x {
    .tick {
      line {
        opacity: 0;
      }
    }

    .domain {
      opacity: 0;
    }
  }

  .tick {
    font-size: 12px;
    fill: $ks-default-text-color-light;
  }

  .c3-legend-item-event {
    font-size: 12px;
  }

  .c3-ygrid-line line {
    stroke: rgba(57, 80, 155, 0.1);
  }
}
