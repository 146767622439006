.tc-form-default {
  .form-group {
    max-width: 800px;
  }

  .tc-form-actions {
    @include media-breakpoint-up('sm') {
      padding-left: 200px;
    }
  }
}

.tc-organisation-form {
  .form-group.paymentMethods {
    width: 100%;
    max-width: 100%;
  }
}

.tc-input-quantity {
  width: 90px;
}

.form-group .thumbnail {
  margin-top: 5px;
  display: table-cell;
  img { // Image thumbnails in form
    max-height: 100px;
    max-width: 200px;
  }
}

#item_requiredItems {
  .form-group, .form-check {
    margin-bottom: 0;
  }
  .row + .row {
    margin-top: 0;
  }
  .row {
    padding: 10px 0 0 0;
  }
}

.tc-dependent-item {
  padding-left: 30px;
}

.mailing-attachment {
  display: inline-block;
  max-width: 90%;
  margin-right: 10px;
  .form-group .col-sm-3 {
    display: none;
  }
  .form-group .col-sm-9 {
    flex: 0 0 100%;
    max-width: 100%;
    padding: 0;
  }
}

.horizontal-radio {
    display: flex;
    .form-check {
        margin-right: 10px;
    }
}

@include media-breakpoint-up(sm) {
    $postalCodeWidth: 125px;
    .col-address-postalCode {
        width: auto;
    }

    .address-postalCode {
        min-width: $postalCodeWidth;
        max-width: $postalCodeWidth;
    }

    .col-address-city {
        width: auto;
        flex-grow: 1;
    }

    .address-city {
        max-width: 450px - $postalCodeWidth - ($grid-gutter-width / 2);
    }
}

[data-fw-at-password-strength-estimator] {
    .invalid-feedback {
        margin-top: 1.5rem;
        margin-bottom: -30px;
    }
}

.search-form {
    input, .btn, .form-control {
        height: 45.45px;

        @include media-breakpoint-down(sm) {
            height: 31px;
        }
    }
}

.tc-compact-form-list {
    ul {
        margin-bottom: 0.5rem;
    }

    .btn.js-remove {
        padding: 0.2rem;
    }
}

.full-height-editor .CodeMirror {
    height: 700px;
}
