body.login
{
    background: url('../../img/login-background.jpg') fixed;
    background-size: cover;
    padding: 0;
    margin: 0;

    &.oauth2-authorize {
        background: $primary;
        color: $white;

        p.forwartLink {
            display: none;
        }

        .login.card {
            background: none;
            box-shadow: none;
        }

        .btn-primary {
            background-color: $white;
            border-color: $white;
            color: $primary;
        }

        .logo {
            background-image: url('../../img/logo-white.svg');
            background-size: contain;
            margin-bottom: 20px;
            img {
                visibility: hidden;
                margin-bottom: 0;
            }
        }
    }

}

body.login.env-staging {
    background-image: url('../../img/login-background-staging.jpg');
}

.card.login {
    img {
        margin-bottom: 20px;
    }

    &.reset-password {
        max-width: 550px;
    }
}
