$category-blue:       #4176A5;
$category-green:      #AEDB54;
$category-orange:     #FFC540;
$category-red:        #CC254A;
$category-lightblue:  #90C3E6;
$category-purple:     #c377e0;

.sp-seatplan {
  position: relative;
  user-select: none;
}

#tc-seating-app {
    position: relative;
}
.tc-seating-load-mask {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(255,255,255,0.9);
    z-index: 999;

    .tc-seating-load-mask-text {
        border: 1px solid #666;
        border-radius: 5px;
        background-color: white;

        padding: 5px 10px;

        width: 200px;
        text-align: center;

        margin: 250px auto;
    }
}

.sp-seat {
  border: 1px solid #ddd;
  text-align: center;
  z-index: 1;

  &.selected { background-color: lighten($warning, 10%) !important; }
  &.unavailable {
    opacity: 0.5;
    background-color: lighten($danger, 25%);
    background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' version='1.1' preserveAspectRatio='none' viewBox='0 0 100 100'><path d='M1 0 L0 1 L99 100 L100 99' fill='black' /><path d='M0 99 L99 0 L100 1 L1 100' fill='black' /></svg>");
    background-repeat:no-repeat;
    background-position:center center;
    background-size: 100% 100%, auto;
  }
  &.is-in-order.available { background-color: lighten($warning, 25%); opacity: 0.5; }
  &.blocked {
    background-color: #ddd;
    background-color: lighten($danger, 25%);
    background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' version='1.1' preserveAspectRatio='none' viewBox='0 0 100 100'><path d='M1 0 L0 1 L99 100 L100 99' fill='black' /><path d='M0 99 L99 0 L100 1 L1 100' fill='black' /></svg>");
    background-repeat:no-repeat;
    background-position:center center;
    background-size: 100% 100%, auto;
  }
  &.available { cursor: pointer; background-color: lighten($success, 30%) }
}

.sp-element {
  text-align: center;
  background-color: #eee;

  display: flex;
  align-items: center;
  justify-content: center;

  z-index: 2;

  &.under {
    background-color: lighten($info, 15%);
    z-index: 0;
    > span {
      position: absolute;
      left: 0;
      height: 20px;
      bottom: -20px;
      font-size: 12px;
      background-color: lighten($info, 15%);
      padding: 0 10px;
    }
  }
}

.sp-seatplan.with-categories {
  .sp-seat {
    &.unavailable { background-color: #ddd; }
    &.available.category-blue       { background-color: $category-blue; color: white; }
    &.available.category-green      { background-color: $category-green; }
    &.available.category-orange     { background-color: $category-orange; }
    &.available.category-red        { background-color: $category-red; color: white; }
    &.available.category-lightblue  { background-color: $category-lightblue; }
    &.available.category-purple     { background-color: $category-purple; color: white; }
  }
}

.sp-row-label, .sp-element, .sp-seat {
  position: absolute;
}

[v-cloak] {
  display: none;
}

.tc-seating-info {
}

.seating-container {
  // overflow: hidden;
  position: relative;

  &:focus {
    outline: none;
    box-shadow: none;
  }

  .range-slider {
    display: inline-block;
  }

  .buttons {
    position: absolute;
    top: -10px;
    right: 15px;
    z-index: 10;
    transform: translateY(-100%);
    // padding: 10px;
    // background-color: rgba(255, 255, 255, .5);

    button {
      -webkit-appearance: none;
      background-color: $ticketcrew-blue;
      color: #fff;
      width: 30px;
      height: 30px;
      border-radius: 50%;
      text-align: center;
      line-height: 32px;
      cursor: pointer;
      border: 0;
      padding: 0;
      font-size: 18px;
      transition: all .2s linear;

      &:focus {
        outline: none;
        box-shadow: none;
      }

      &:hover {
        background-color: darken($ticketcrew-blue, 5%);
      }
    }

    @include media-breakpoint-down(md) {
      position: relative;
      transform: translateY(0);
      top: 0;
      right: auto;
      text-align: center;
      margin-top: 30px;
    }
  }
}

.floorplan-zoom-container {
  border: 5px solid #fff;
  box-shadow: 0 0 3px rgba(0,0,0, .25);
  overflow: hidden;
  margin: 10px 0;
  position: relative;
  z-index: 5;
    max-height: 500px;

  &:focus {
    outline: none;
    box-shadow: none;
  }
}

.floorplan-zoom {
  &:focus {
    outline: none;
    box-shadow: none;
  }
}

.tc-seating-selection {
  background-color: #eee;
  padding: 10px;
  border-radius: 5px;
  width: 350px;
  margin-bottom: 15px;
}

.tc-seating-help {
  padding-top: 10px;
}

.tc-seating-selection .loading-indicator {
  visibility: hidden;
  &.visible {
    visibility: visible;
  }
}


#tc-seating-app {
  .order-summary {
    ul {
      margin: 0;
      padding: 0;
      list-style-type: none;

      li {
        margin: 0 0 1rem 0;
        padding: 0;

        label {
          margin-bottom: 0;
        }
      }
    }
  }

  @include media-breakpoint-down(sm) {
    .order-summary {
      position: fixed;
      left: 0;
      width: 100%;
      max-width: 100%;
      right: 0;
      bottom: 0;
      height: 40px;
      box-shadow: 0 0 3px rgba(0,0,0, .25);
      z-index: 99;
      background-color: white;

      .summary-line {
        padding: .25rem 0;

        >.col {
          margin: .50rem;
        }
      }

      &:not(.expanded) {
        .order-summary-detail {
          display: none;
        }
      }
      &.expanded {
        height: 75vh;
        .order-summary-detail {
          overflow-y: scroll;
        }
      }
    }
  }
}

.seatplan-legend {
  span.sp-legend-seat {
    display: inline-block;
    position: relative;
    border: 1px solid #ddd;
    text-align: center;
    z-index: 1;
    width: 40px;
    margin-left: 20px;

    &.selected { background-color: lighten($warning, 10%) !important; }
    &.unavailable {
      background-color: lighten($danger, 25%);
      background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' version='1.1' preserveAspectRatio='none' viewBox='0 0 100 100'><path d='M1 0 L0 1 L99 100 L100 99' fill='black' /><path d='M0 99 L99 0 L100 1 L1 100' fill='black' /></svg>");
      background-repeat:no-repeat;
      background-position:center center;
      background-size: 100% 100%, auto;
      opacity: 0.5;
    }
    &.blocked {
      background-color: #ddd;
      background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' version='1.1' preserveAspectRatio='none' viewBox='0 0 100 100'><path d='M1 0 L0 1 L99 100 L100 99' fill='black' /><path d='M0 99 L99 0 L100 1 L1 100' fill='black' /></svg>");
      background-repeat:no-repeat;
      background-position:center center;
      background-size: 100% 100%, auto;
    }
    &.available { background-color: lighten($success, 30%) }
    &.category-blue       { background-color: $category-blue; color: white; }
    &.category-green      { background-color: $category-green; }
    &.category-orange     { background-color: $category-orange; }
    &.category-red        { background-color: $category-red; color: white; }
    &.category-lightblue  { background-color: $category-lightblue; }
    &.category-purple     { background-color: $category-purple; color: white; }

    @include media-breakpoint-down(sm) {
      display: block;
      margin: 0 5px 0 0;
      flex: 1;
    }
  }

  @include media-breakpoint-down(sm) {
    p {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      font-size: 11px;

      strong {
        width: 100%;
      }

      .sp-legend-label {
        margin-right: 5px;
        flex: 1;
      }
    }
  }

  @include media-breakpoint-down(md) {
    text-align: center;
    margin: 0 auto;
  }
}
