.fw-color-picker {
    .input-group-append {
        width: 42px;
        overflow: hidden;
    }
    .colorpicker-input-addon {

        i {
            display: inline-block;
            cursor: pointer;
            vertical-align: text-top;
            height: 16px;
            width: 16px;
            position: relative;
        }
    }
}
