#tc-webshop-items-app {
    margin: 15px 0;
    .event-item {
        border: 1px solid #dee0e1;
        padding: 5px 3px;
        margin-bottom: 5px;
    }

    .webshop-item {
        display: flex;
        flex-direction: row;
        align-items: center;
        border: 1px solid #dee0e1;
        padding: 3px;
        margin-bottom: 5px;

        .handle {
            margin-left: 5px;
        }

        [data-field="display-order"] {
            width: 40px;
            margin-right: 2px;
            text-align: center;
        }

        [data-field="title"] {
            border-bottom-right-radius: 0;
            border-top-right-radius: 0;
        }

        [data-field="description"] {
            border-bottom-left-radius: 0;
            border-top-left-radius: 0;
            border-left: none;
        }

        .btn {
            margin-left: 5px;
        }
    }

    @include media-breakpoint-up(md) {
        .tc-webshop-item-list {
            min-height: 700px;
        }
    }
}
