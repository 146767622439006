.fw-pdf-viewer {

  .modal-content {
    border-radius: 6px 6px 0 0;
  }

  .modal-body {
    padding: 0;

    display: flex;

    iframe {
      flex: 1;
      border: 0;
      position: relative;
      width: 100%;
      height: 100%;
        min-height: 500px;
    }
  }

}
