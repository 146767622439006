[data-fw-at-image-upload] {
    img {
        width: 100%;
        max-width: 100%;
    }

    .placeholder-label {
        display: block;
        background-color: #ccc;
        text-align: center;
        width: 100%;
        min-height: 50px;

        cursor: pointer;
    }
}
